import React, { useState,useEffect } from "react";
import VizSensor from "react-visibility-sensor";
import axios from "axios";
import envConfig from "../../bin/env.config";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CircularProgress from '@material-ui/core/CircularProgress';

import { format } from "date-fns";
import { useSelector } from "react-redux";
export default function ProductStoreMostRecentPriceItem({productStore,productId,companyId}) {
  const token = useSelector((state) => state.session.token);
  const [mostRecent, setMostRecent] = useState({id:'-', edited_at:'-', price:'-'  });
  const [isVisible, setIsVisible] = useState(false);
  const doLoadData = (isVisibleChanged) => {
    isVisibleChanged&&setIsVisible(isVisibleChanged)    
  }; 
  const handOpenHistoryPrices = (productStore) => {
    window.open(`/product-store-history-prices/${productId}/${companyId}/${productStore.id}/${encodeURIComponent(productStore.address)}`, '_blank');
  }; 
  useEffect(() => {
    const fetchData = async () => {
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/product-store/recent-price`;
      const result = await axios.post(url,
        {
          productId:productId,
          storeId:productStore.id,
        },
        {
          headers: {
            Authorization: token,
          },
        }); 
        
      setMostRecent(result.data.data);
    };    
    productId>0&&isVisible&& fetchData();    
  }, [productStore, productId, isVisible]);
  return ( 
    // <>
    // {mostRecent.id!==0&&(
    <VizSensor onChange={doLoadData}>
      <TableRow key={productStore.id}>
        <TableCell align="left">{productStore.name}</TableCell>
        <TableCell align="left">{productStore.address}</TableCell>
        <TableCell align="left">{productStore.city}</TableCell>
        <TableCell align="left">{productStore.province}</TableCell>
        <TableCell align="left">                        
          {mostRecent.id==='-'?(
                          <CircularProgress size={16} />
                        ):(
                          <>{mostRecent.edited_at!='-' 
                            ? format(new Date(mostRecent.edited_at), "yyyy-MM-dd")
                            : '-'}</>
                        )}          
        </TableCell>
        <TableCell align="left">
          {mostRecent.id==='-'?(
                        <CircularProgress size={16} />
                      ):(
                        <>{mostRecent.price||'-'}</>
                      )}
        </TableCell>                
        <TableCell align="left">
                    {mostRecent.id==='-'?(
                        <CircularProgress size={16} />
                      ):(
                        <>{mostRecent.id>0&&(
                          <IconButton
                              color="inherit"
                              onClick={() => handOpenHistoryPrices(productStore)}
                              aria-label="Close"
                            >
                              <ArrowForwardIosIcon />
                          </IconButton>
                        )}</>
                      )}
        
        </TableCell>                
      </TableRow>
  </VizSensor>
    // )}
    // </>
            
  );
}
