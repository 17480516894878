import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import axios from "axios";
import envConfig from "../../bin/env.config";
import CircularProgress from "@material-ui/core/CircularProgress";
import VizSensor from "react-visibility-sensor";
const useStyles = makeStyles((theme) => ({
  productImage: {
    objectFit: "contain", //
    margin: theme.spacing(1),
    marginLeft: theme.spacing(3),
  }, 
  loading: {
    color: theme.palette.secondary.main,
  },
  imageContainer: {
    display: "block",
    position: 'relative',
    "&:hover": {
      cursor: "pointer",
      color: "rgb(0 176 79 / 100%)",
    },
  },
  faImagesIcon:{
    position: "absolute", 
    top:"10px", 
    left:"10px"
  },
  divImageContainer: {
    display: "flex",
    width:"100%",
    height:"400px",
    alignItems: "center", 
    justifyContent:"center",
    position: "relative",
    "&:hover": {
      cursor: "pointer",      
    },
  },
}));
export default function ProductImageByCompanyProductId(props) {
  const classes = useStyles();
  const {company_product_id} =props
  const [productImage, setProductImage] = React.useState({image_url: '', company_id: 0});  
  const state = useSelector(state => state);
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const addDefaultSrc = (event)=>{
    event.target.src = '/resources/images/no-picture-available.png'
  }
  useEffect(() => {
    const fetchDataSource = async () => {
      setIsLoading(true);
      const apiUrl = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/product-images-by-cpid`;
      const postData = {
        id: company_product_id,
      };
      try {
        const result = await axios.post(apiUrl, postData,
          {
            headers: {
              Authorization: state.session.token,
            },
          });
        setProductImage(result.data.data);        
      } catch (e) {
        console.log(e)
      }
      setIsLoading(false);
    };
    isVisible&& fetchDataSource();
  }, [isVisible,company_product_id]);
  
  const doLoadData = (isVisibleChanged) => {
    isVisibleChanged&&setIsVisible(isVisibleChanged)    
  };  
  return (
    <VizSensor onChange={doLoadData}>
      <>
        {!isVisible ||isLoading ? (          
          <CircularProgress className={classes.loading} />
        ) : (
          <img                  
            className={classes.productImage}
            {...props}
            src={
                productImage.image_url
                ? productImage.image_url
                : `https://bettercart.ca/resources/images/no-picture-available.png`
            }
            onError={addDefaultSrc}
          /> 
        )}
      </>
    </VizSensor>
      
  );
}
