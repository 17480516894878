import React, { useState, useEffect } from "react";
import { useHistory,Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";

import { format } from "date-fns";
import CompanyLogo from "../Common/CompanyLogo";

// import ProductStoreMostRecentPriceItem from "./ProductStoreMostRecentPriceItem";
import ProductCompanyInformation from "./ProductCompanyInformation";

import {  useSelector } from "react-redux";
import axios from "axios";
import envConfig from "../../bin/env.config";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  table: {
    minWidth: 350,
    '& tbody>.MuiTableRow-root:hover': {
      background: '#DDD',
    }
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  title: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
  },
  companyLogo: {
    marginLeft: theme.spacing(1),
    height: "50px",
  },
}));

export default function ProductStoreHistoryPricePage(props) {
  const history = useHistory();
  const classes = useStyles();
  const productId = props.match.params.product_id;
  const companyId = props.match.params.company_id;
  const storeId = props.match.params.store_id;
  const storeAddress = props.match.params.store_address;
  const [isLoading, setIsLoading] = useState(false);
  const [city, setCity] = useState('');
  const [dataSource, setDataSource] = useState([]);
  const token = useSelector(state => state.session.token);
  const loader = document.querySelector(".loader");
  // if you want to show the loader when React loads data again
  const showLoader = () => loader.classList.remove("loader--hide");

  const hideLoader = () => {
    loader.classList.add("loader--hide");
  }

  useEffect(() => {
    const fetchData = async () => {
      showLoader();
      setIsLoading(true);
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/product-store/prices`;
      const result = await axios.post(
        url,
        { 
          productId: productId,
          storeId: storeId
        },
        {
          headers: {
            Authorization: JSON.stringify(token),
          },
        }
      );
      setDataSource(result.data.data);
      setIsLoading(false);
      hideLoader();
      
    };

    productId&&storeId&&fetchData();
  }, [productId,storeId]);
  
  return (
    <>
      <AppBar position="sticky" color="inherit">
        <Toolbar>
          {/* <IconButton
            color="inherit"
            onClick={() => history.goBack()}
            aria-label="Close"
          >
            <ArrowBackIcon />
          </IconButton> */}
          <Typography className={classes.title} variant="h6" noWrap>
          <Box display="flex" alignItems="center">
            Product ID: #{productId}
            <CompanyLogo
              company_id={companyId}
              className={classes.companyLogo}
            />
            <b>@{storeAddress}</b>
          </Box>
        </Typography>
          {/* <SearchBox doSearch={doSearch} placeholder={"Search by product's name"} value={searchText} searchOnChange={false} /> */}
        </Toolbar>
      </AppBar>
      {isLoading ? (
        <Grid
          className={classes.root}
          container
          alignItems="center"
          justifyContent="center"
        >
          <div>Loading ...</div>
        </Grid>
      ) : (
        <Grid
          className={classes.root}
          container
          alignItems="center"
          justifyContent="center"
        >
          <ProductCompanyInformation productId={productId} companyId={companyId}/> 
         <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left" style={{width:"100px"}}>Date</TableCell>
                  <TableCell align="left">Price</TableCell>                  
                </TableRow>
              </TableHead>
              <TableBody>
                {dataSource.map((row, i) => (
                  <TableRow>
                    <TableCell align="left">
                      {format(new Date(row.edited_at), "yyyy-MM-dd")}
                    </TableCell>
                    <TableCell align="left">
                      ${row.price}
                    </TableCell>  
                    </TableRow>
                ))}
                {/* {loadMore && (
                  <ItemLoading doLoadMore={doLoadMore} colSpanValue={4} />
                )} */}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
    </>
  );
}
