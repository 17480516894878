import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
// import CardHeader from '@material-ui/core/CardHeader';

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
// import CardMedia from "@material-ui/core/CardMedia";
import {CardMediaCustom} from "../../../Custom/CardMediaCustom";
import CardHeader from "@material-ui/core/CardHeader";
import Chip from "@material-ui/core/Chip";
import AccountCircle from "@material-ui/icons/Face";
import Typography from "@material-ui/core/Typography";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import { Helpers } from "../../../../utility/helpers";
import { useSelector } from "react-redux";

import ProductAttributesInfo from "../../../Product/ProductAttributesInfo";
import CompanyLogo from "../../../Common/CompanyLogo";
import DialogImageZoomIn from "../../../Product/DialogImageZoomIn";
import EditNoteTextBox from "./EditNoteTextBox";

const useStyles = makeStyles((theme) => ({
  media: {
    //  object-fit is not supported by IE11.
    objectFit: "contain",
    cursor: "pointer",
    width: 200,
    height: 200,
    marginLeft: "auto",
    marginRight: "auto",
    paddingTop: "10px",
    "&:hover": {
      opacity: "0.7",
    },
    [theme.breakpoints.down("md")]: {
      // width: '100px',
      // height: '100px',
    },
  },
  card: {
    margin: "10px",
    paddingBottom: "10px",
    position: "relative",
  },
  cardContent: {
    height: "200px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      boxSizing: "border-box",
    },
    overflow: "auto",
  },
  centeredCardConent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  notMatchButton: {
    color: "red",
    borderColor: "red",
  },
  // removeAcceptedButton: {
  //   color: "blue",
  //   borderColor: "blue",
  // },
  original: {
    backgroundColor: theme.palette.secondary.main,
    position: "-webkit-sticky",
    position: "sticky",
    top: 64,
  },
  originalCard: {},
  container: {
    paddingTop: "8px",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridTemplateRows: "1fr 1fr",
    justifySelf: "start",
    backgroundColor: "#DDD",
  },
  item: {
    padding: "4px",
  },
  companyName: {
    fontWeight: "bold",
  },
  tableTd: {
    padding: "0px",
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  lockedByYou: {
    backgroundColor: theme.palette.primary.main,
  },
  lockedByOther: {
    backgroundColor: "red",
    color: "#FFF",
  },
  groupLink:{
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    cursor: "pointer"
  }
}));

export default function ReviewItemOriginal(props) {
  const classes = useStyles();
  const { originalProduct, locked } = props;
  let productImageUrl = originalProduct.product.image_url;
  
  return (
    <Grid item xs={12} sm={12} md={4} lg={4} className={classes.original}>
      <Card className={`${classes.card} ${classes.originalCard}`}>
        <CardHeader
          title={
            <CompanyLogo company_id={originalProduct.product.company_id}
                              style={{ height: "50px" }}                  
                            />            
          }
        />
        <DialogImageZoomIn imageUrl={productImageUrl}/>
        <CardMediaCustom
                  className={classes.media}
                    title={originalProduct.product.name}
                    company_product_id={originalProduct.product.company_product_id}
                  />
        
        <CardContent className={classes.cardContent}>
          <Typography className={classes.productName} variant="body2">
            {Helpers.titleCase(originalProduct.product.fullname)}
          </Typography>
          <Typography className={classes.companyName} variant="body2">
            {/* {productMatch.company_name} */}
            {originalProduct.product.size} ID#{originalProduct.product.id} {" "}  
            {originalProduct.product.product_matching_group_id > 0 && (
                        <a
                          href={`/matching-group/${originalProduct.product.product_matching_group_id}`}
                          target="_blank"
                          className={classes.groupLink} 
                        >
                          {`G#${originalProduct.product.product_matching_group_id}`}
                        </a>
                      )}
          </Typography>
          <Table className={classes.table}>
            <TableBody>
              {originalProduct.product.storeProductPrices &&
                originalProduct.product.storeProductPrices.map(
                  (storeProductPrice, i) => (
                    <TableRow key={i}>
                      <TableCell className={classes.tableTd}>
                        {storeProductPrice.store_name}
                      </TableCell>
                      <TableCell className={classes.tableTd}>
                        ${storeProductPrice.current_price_ea}
                        {/* /Reg:${storeProductPrice.regular_price_ea} */}
                      </TableCell>
                      <TableCell className={classes.tableTd}>
                        {storeProductPrice.sold_by_type_name}
                      </TableCell>
                      <TableCell className={classes.tableTd}>
                        {storeProductPrice.sale_description}
                      </TableCell>
                    </TableRow>
                  )
                )}
            </TableBody>
          </Table>
        </CardContent>
        <CardActions>
          <Typography variant="body2">
            <ProductAttributesInfo productId={originalProduct.product.id}/>
            {/* <b>sku:</b>
            {originalProduct.fcl_sku} <b>upc:</b>
            {originalProduct.fcl_upc}
            <br />
            {originalProduct.fcl_title}
            <br />
            <b>brand:</b>
            {originalProduct.fcl_brand} <b>size:</b>
            {originalProduct.fcl_size} */}
          </Typography>
        </CardActions>
        <CardActions>
          {locked && (
            <Chip
              icon={<AccountCircle />}
              label={`${locked.firstName} on this product`}
              className={
                locked.byYou ? classes.lockedByYou : classes.lockedByOther
              }
              color="secondary"
            />
          )}
        </CardActions>
        <CardActions>
          <EditNoteTextBox
            productId={originalProduct.id}
            note={originalProduct.note}
          />
        </CardActions>
      </Card>
    </Grid>
  );
}
