import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
// import CardHeader from '@material-ui/core/CardHeader';

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
// import CardMedia from "@material-ui/core/CardMedia";
import {CardMediaCustom} from "../../Custom/CardMediaCustom";
import CardHeader from "@material-ui/core/CardHeader";
import Chip from "@material-ui/core/Chip";
import AccountCircle from "@material-ui/icons/Face";
import WarningIcon from "@material-ui/icons/Warning";
// import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
// import Slide from "@material-ui/core/Slide";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import axios from "axios";
import envConfig from "../../../bin/env.config";
import store from "../../../store/store";

import { Helpers } from "../../../utility/helpers";
import { useSelector } from "react-redux";
import CompanyLogo from "../../Common/CompanyLogo";
const useStyles = makeStyles((theme) => ({
  media: {
    //  object-fit is not supported by IE11.
    objectFit: "contain",
    cursor: "pointer",
    width: 'auto',
    height: 200,
    marginLeft: "auto",
    marginRight: "auto",
    paddingTop: "10px",
    "&:hover": {
      opacity: "0.7",
    },
    [theme.breakpoints.down("md")]: {
      // width: '100px',
      // height: '100px',
    },
  },
  card: {
    margin: "10px",
    paddingBottom: "10px",
    position: "relative",
  },
  cardContent: {
    height: "200px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      boxSizing: "border-box",
    },
    overflow: "auto",
  },
  centeredCardConent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  notMatchButton: {
    color: "red",
    borderColor: "red",
  },
  // removeAcceptedButton: {
  //   color: "blue",
  //   borderColor: "blue",
  // },
  original: {
    backgroundColor: theme.palette.secondary.main,
    position: "-webkit-sticky",
    position: "sticky",
    top: 64,
  },
  originalCard: {},
  matched: {
    // backgroundColor: theme.palette.primary.main,
  },
  matchedCard: {
    border: `2px solid`,
    borderColor: theme.palette.primary.main,
  },
  potential: {},
  potentialCard: {},
  container: {
    paddingTop: "8px",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridTemplateRows: "1fr 1fr",
    justifySelf: "start",
    backgroundColor: "#DDD",
  },
  item: {
    padding: "4px",
  },
  companyName: {
    fontWeight: "bold",
  },
  tableTd: {
    padding: "0px",
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  lockedByYou: {
    backgroundColor: theme.palette.primary.main,
  },
  lockedByOther: {
    backgroundColor: "red",
    color: "#FFF",
  },
  wrongListLink: {
    textDecoration: "none",
    color: theme.palette.primary.main,
    cursor: "pointer",
    marginLeft: "auto",
    fontSize: "0.8125rem",
  },
  moreButtons:{
    minWidth:"40px"
  }
}));

export default function ReviewItem(props) {
  const history = useHistory();
  const classes = useStyles();

  const {
    productMatch,
    groupId, 
    handleNotAMatch,
    handleMatches, 
    handleIsADQ  
  } = props;
  const companies = useSelector(state => state.companies.companies);

  let productImageUrl = productMatch.image_url;
  const [isADQ, setIsADQ] = useState(productMatch.is_adq);

  const handleNotASimilarMatchLocal = () => {
    const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/similar/wrong`;
    const postData = {
      product_id: productMatch.product_id,
      adq_product_mapping_id: groupId,
    };
    axios
      .post(url, postData, {
        headers: {
          Authorization: JSON.stringify(store.getState().session.token),
        },
      })
      .then((response) => {
        handleNotAMatch && handleNotAMatch(productMatch);      
      })
      .catch((err) => {
        console.log(err);
      });
    //  onRemove&&onRemove(productMatch.id);
  };
  const handleIsADQProduct= (is_adq) => {
    const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/similar/is-adq`;
    const postData = {
      product_id: productMatch.product_id,
      adq_product_mapping_id: groupId,
      is_adq: is_adq
    };
    setIsADQ(is_adq)
    axios
      .post(url, postData, {
        headers: {
          Authorization: JSON.stringify(store.getState().session.token),
        },
      })
      .then((response) => {
        // handleIsADQ && handleIsADQ(productMatch,is_adq);      
      })
      .catch((err) => {
        console.log(err);
      });
    //  onRemove&&onRemove(productMatch.id);
  };
  const handleSimilarMatchesLocal = () => {
    const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/similar/matches`;
    // console.log('here111-------------------------------')
    // console.log(groupId)
    // console.log(productMatch)

    if (groupId && productMatch.product_id > 0) {
      const postData = {
        adq_product_mapping_id: groupId,
        product_id: productMatch.product_id,
      };
      axios
        .post(url, postData, {
          headers: {
            Authorization: JSON.stringify(store.getState().session.token),
          },
        })
        .then((response) => {
          handleMatches && handleMatches(productMatch); //recheck this ---> it should be handleMatches
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={4}
        className={
           productMatch.type === "matched"
            ? classes.matched
            : classes.potential
        }
      >
        <Card
          className={`${classes.card} ${
            productMatch.type === "matched"
              ? classes.matchedCard
              : classes.potentialCard
          }`}
        >
          <CardHeader
            title={
              <CompanyLogo company_id={productMatch.company_id}
                                style={{ height: "50px" }}                  
                              />
            }
          />
          <CardMediaCustom
                        className={classes.media}
                        title={productMatch.name}
                        company_product_id={productMatch.company_product_id}
                      />
         
          <CardContent className={classes.cardContent}>
            <Typography className={classes.productName} variant="body2">
              {Helpers.titleCase(productMatch.fullname)}
            </Typography>
            <Typography className={classes.companyName} variant="body2">
              {/* {productMatch.company_name} */}
              {productMatch.size} #{productMatch.product_id}
            </Typography>
            {productMatch.score && (
              <Typography variant="body2">
                <strong>score:</strong>
                {productMatch.score}
                {productMatch.product_matching_group_id && (
                  <Chip
                    variant="outlined"
                    size="small"
                    icon={<WarningIcon />}
                    label={`Belonged to #${productMatch.product_matching_group_id}`}
                    color="secondary"
                    component="a"
                    href={`/matching-group/${productMatch.product_matching_group_id}`}
                    clickable
                  />
                )}
              </Typography>
            )}            
            
          </CardContent>
          <CardActions>
            {productMatch.type === "prediction" && (
              <> 
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.notMatchButton}
                  onClick={handleNotASimilarMatchLocal}
                >
                  Not A Similar
                </Button>              
                <Button
                  variant="contained"
                  size="small"
                  className={classes.removeAcceptedButton}
                  onClick={handleSimilarMatchesLocal}
                  color="secondary"
                >
                  Similar
                </Button>
              </>
            )}
            {productMatch.type === "matched" && (
              <>
              <Button
                variant="contained"
                size="small"
                className={classes.removeAcceptedButton}
                onClick={handleNotASimilarMatchLocal}
                color="primary"
              >
                Not A Similar Match
              </Button>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isADQ}
                    onChange={() => handleIsADQProduct(!isADQ)}
                    name="is_adq"
                    color="secondary"
                  />
                  }
                  label="Is ADQ"
              />
              </>
            )}
          </CardActions>
        </Card>
      </Grid>
  );
}
