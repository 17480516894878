import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableFooter,
  TableRow,
  TableSortLabel ,
  Typography,
  IconButton,
  Paper,
  Button
} from "@material-ui/core";
import SearchBox from "./Products/SearchBox";
import ScoreBox from "./Products/ScoreBox";
import axios from "axios";
import envConfig from "../../bin/env.config";
import store from "../../store/store";
import ButtonSelectGroupId from "./Products/ButtonSelectGroupId";
import ButtonSelectCompetitorGroupId from "./CompetitorProducts/ButtonSelectCompetitorGroupId";
import ProductAttributesInfo from "../Product/ProductAttributesInfo";
import CompanyDropDownBox from "../ProductMatching/CompanyDropDownBox";
// import { Helpers } from "../../../../utility/helpers";
import { useSelector } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import VizSensor from "react-visibility-sensor";
import CompanyLogo from "../Common/CompanyLogo";
// import ProductDetail from './ProductDetail';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  grow: {
    flexGrow: 1,
  },
  title: {
    //marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  table: {
    minWidth: 350,
    "& tbody>.MuiTableRow-root:hover": {
      background: "#DDD",
    },
  },
  potentialProductImage: {
    width: 50,
    height: 50,
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      width: 25,
      height: 25,
    },
    // cursor: "pointer",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});
export function RowItem(props){
  const classes = useStyles();
  const {product, row, reloadData,row_number,isCompetitor} = props; 
  const [isVisible, setIsVisible] = useState(false);
  const doLoadData = (isVisibleChanged) => {
    isVisibleChanged&&setIsVisible(isVisibleChanged)    
  }; 
  return (
    <VizSensor onChange={doLoadData}>
          <TableRow>
            {!isVisible?(                                 
              <TableCell align="left" colSpan={8}><CircularProgress size={16} /></TableCell>            
            
            ):(
              <>                   
                <TableCell align="left">{row_number+1}</TableCell>
                <TableCell align="left">
                  {!isCompetitor&&<ButtonSelectGroupId product={product} potentialProduct={row}  reloadData={reloadData}/>} 
                  {isCompetitor&&<ButtonSelectCompetitorGroupId product={product} potentialProduct={row}  reloadData={reloadData}/>} 
                </TableCell>
                <TableCell align="left">{row.id}</TableCell>
                <TableCell >
                  <img
                    className={classes.potentialProductImage}
                    src={row.image_url}
                    // onClick={() => handGroupView(productMatching.id)}
                  />
                </TableCell>
                <TableCell align="left">
                  {row.name}
                  <ProductAttributesInfo productId={row.id}/>
                </TableCell>
                <TableCell align="left">{row.size}</TableCell>
                <TableCell align="left">{row.brand}</TableCell>  
                <TableCell align="center">  
                  <CompanyLogo company_id={row.company_id}
                                    // alt={productMatch.company_name}
                                    style={{ height: "50px" }}                  
                                  />                    
                </TableCell>                  
              </>  
            )}
          </TableRow>
        </VizSensor>    
  )
}
export default function DialogFindGroupId(props) {
  const classes = useStyles();
  const { open, onClose, product, reloadData, isCompetitor} = props;
  const [isLoading, setIsLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [scoreValue, setScoreValue] = useState('0.4');
  const [searchText, setSearchText] = useState('');
  const [searchType, setSearchType] = useState(3);
  const [limit, setLimit] = useState(200);
  const [skip, setSkip] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [groupOnly, setGroupOnly] = useState(false);
  const [dataSource, setDataSource] = useState([]);

  const companies = useSelector(state => state.companies.companies);
  const selectedCompany = companies&&companies.find(company=>company.selected);
  
  useEffect(() => {
    // const controller = new AbortController();
    const fetchData = async () => {
      setIsLoading(true);
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/company-report-tool/find-group_id?orderBy=${orderBy}&order=${order}&limit=${limit}&skip=${skip}&c=${selectedCompany?selectedCompany._id:0}`;
      const result = await axios.post(
        url,
        { q: searchText,searchType: searchType, score:scoreValue, cproduct_id: product.id, is_competitor: isCompetitor,groupOnly: groupOnly},
        {
          // signal:controller.signal,
          headers: {
            Authorization: JSON.stringify(store.getState().session.token),
          },
        }
      );

      if (skip === 0) {
        setDataSource(result.data.data);
      } else {        
        setDataSource([...dataSource, ...result.data.data]);
      }

      setLoadMore(result.data.data.length === limit);
      setIsLoading(false);
    };
    open&&(searchText||(searchType>=3))&&fetchData();
    // return () => controller.abort();
  }, [searchText, searchType, scoreValue, skip, order, orderBy, groupOnly, open, selectedCompany]);
  const doSearch = (q) => {
    setSearchText(q);
    setSkip(0);
  };
  const doChangeScore = (score) => {
    setScoreValue(score);
    setSkip(0);
  };
  const handleLoadMoreClick = () => {
    setSkip(skip + limit);
  };
  const handleSearchTypeChange = (event) => {
    setSearchType(event.target.value);
    setSkip(0);
  };
  
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar position="sticky" color="inherit">
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={onClose}
              aria-label="Close"
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography className={classes.title} variant="h6" noWrap>
             {/* {product.brand} {product.name} {product.size} */}
             {isCompetitor?'Find a competitor/similar products on BetterCart':'Find a product on BetterCart'}
            </Typography>  
            <div className={classes.grow} />
            <FormControlLabel
                control={
                  <Checkbox
                    checked={groupOnly}
                    onChange={() => setGroupOnly(!groupOnly)}
                    name="groupOnly"
                    color="secondary"
                  />
                }
                label="GroupOnly"
             />
            <Select  value={searchType} onChange={handleSearchTypeChange}>
              <MenuItem value="1">
                <em>Elastic Search</em>
              </MenuItem>
              <MenuItem value="2">
                <em>PostgreSQL Similar</em>
              </MenuItem>
              <MenuItem value="4" >
                <em>PostgreSQL ilike</em>
              </MenuItem>
              <MenuItem value="5" >
                <em>PostgreSQL ilike Attributes</em>
              </MenuItem>
              <MenuItem value="3" >
                <em>ML Minhash</em>
              </MenuItem>
              {/* <MenuItem value="4">
                <em>PostgreSQL tsvector</em>
              </MenuItem> */}
            </Select>
            <CompanyDropDownBox/>
            {searchType==3&&(
              <ScoreBox value={scoreValue} doChangeScore={doChangeScore}/>
            )} 
            <SearchBox value={searchText} doSearch={doSearch} searchOnChange={false} placeholder="Search for the product group to use"/>
          </Toolbar>
        </AppBar>
        {isLoading ? (
        <Grid
          className={classes.root}
          container
          alignItems="center"
          justifyContent="center"
        >
          <div>Loading ...</div>
        </Grid>
      ) : (
        <Grid
          className={classes.root}
          container
          alignItems="center"
          justifyContent="center"
        >
          
          <></>
        <TableContainer component={Paper}>
          <Table className={classes.table} stickyHeader aria-label="simple table">
           

            <TableHead >
              <TableRow>    
                <TableCell align="center" colSpan={7}>
                {product&&(
                    <>
                    <h3> {product.brand} {product.name} {product.size} </h3>
                    <div><b>Key words: </b>{product.key_words}</div>
                    <div style={{color:'red',fontStyle:'italic'}}>If you don't see any results, please check the settings for banners and cities.</div>
                    </>
                  )}
                </TableCell>
              </TableRow>  
              <TableRow>    
                <TableCell align="left">
                  #                
                </TableCell>            
                <TableCell align="left">
                  Group Id                
                </TableCell>
                <TableCell align="left">
                  Product Id                
                </TableCell>
                <TableCell align="left" >                    
                  Image
                </TableCell>       
                <TableCell align="left" >                    
                  Name
                </TableCell>
                <TableCell align="left">
                  Size
                </TableCell>
                <TableCell align="left">
                  Brand
                </TableCell>
                <TableCell align="center">
                  Company
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataSource.map((row, i) => (
                <RowItem product={product} row={row} reloadData={reloadData} row_number={i} isCompetitor={isCompetitor}/>
              ))}
              {dataSource&&(
                <TableRow>                   
                  <TableCell colSpan={7}>Search for the product group to use</TableCell>
                </TableRow>
              )}
              
            </TableBody>      
            {/* <TableFooter>
              {loadMore && (
                <TableRow> 
                  <TableCell align="left" colSpan={6}>
                    <Button  variant="text" onClick={handleLoadMoreClick}>
                      Load more
                    </Button>
                  </TableCell>
                </TableRow>
              )} 
            </TableFooter>         */}
          </Table>
        </TableContainer>
      </Grid>
      )}
       
    </Dialog>
  );
}
