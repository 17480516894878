import React, { useState, useEffect } from "react";
import { useHistory,Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";

import CompanyLogo from "../Common/CompanyLogo";

import ProductStoreMostRecentPriceItem from "./ProductStoreMostRecentPriceItem";
import ProductCompanyInformation from "./ProductCompanyInformation";

import {  useSelector } from "react-redux";
import axios from "axios";
import envConfig from "../../bin/env.config";
import CitiesDropDownBox from "./CitiesDropDownBox";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  table: {
    minWidth: 350,
    '& tbody>.MuiTableRow-root:hover': {
      background: '#DDD',
    }
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  title: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
  },
  companyLogo: {
    marginLeft: theme.spacing(1),
    height: "50px",
  },
}));

export default function ProductStorePage(props) {
  const history = useHistory();
  const classes = useStyles();
  const productId = props.match.params.product_id;
  const companyId = props.match.params.company_id;
  const [isLoading, setIsLoading] = useState(false);
  const [cities, setCities] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [dataSourceFilter, setDataSourceFilter] = useState([]);
  const token = useSelector(state => state.session.token);
  const loader = document.querySelector(".loader");
  // if you want to show the loader when React loads data again
  const showLoader = () => loader.classList.remove("loader--hide");

  const hideLoader = () => {
    loader.classList.add("loader--hide");
  }

  useEffect(() => {
    const fetchData = async () => {
      showLoader();
      setIsLoading(true);
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/product-store/stores`;
      const result = await axios.post(
        url,
        { 
          companyId: companyId
        },
        {
          headers: {
            Authorization: JSON.stringify(token),
          },
        }
      );
      let prepareCitiesData = Array.from(new Set(result.data.data.map(row => JSON.stringify({city: row.city, province: row.province}))))
        .map(str => {
          let {city, province} = JSON.parse(str);
          return {
            city,
            province,           
          };
        });
            
      setCities(prepareCitiesData);
      setDataSource(result.data.data);
      setDataSourceFilter(result.data.data);
      setIsLoading(false);
      hideLoader();      
    };

    productId&&fetchData();
  }, [productId]);
  const onChange =(selectedCity) => {
    // console.log(selectedCity);
    if(selectedCity.city !== 'All cities') {
      setDataSourceFilter(dataSource.filter(row => row.city === selectedCity.city && selectedCity.province === selectedCity.province));
    } else {
      setDataSourceFilter(dataSource);
    }
  };
  return (
    <>
      <AppBar position="sticky" color="inherit">
        <Toolbar>
          {/* <IconButton
            color="inherit"
            onClick={() => history.goBack()}
            aria-label="Close"
          >
            <ArrowBackIcon />
          </IconButton> */}
          <Typography className={classes.title} variant="h6" noWrap>
          <Box display="flex" alignItems="center">
            Product ID: #{productId}
            <CompanyLogo
              company_id={companyId}
              className={classes.companyLogo}
            />
          </Box>
        </Typography>
          {/* <SearchBox doSearch={doSearch} placeholder={"Search by product's name"} value={searchText} searchOnChange={false} /> */}
          {isLoading ?'':
          <CitiesDropDownBox cities={cities} onChange={onChange}/>
          }   
        </Toolbar>
      </AppBar>
      {isLoading ? (
        <Grid
          className={classes.root}
          container
          alignItems="center"
          justifyContent="center"
        >
          <div>Loading ...</div>
        </Grid>
      ) : (
        <Grid
          className={classes.root}
          container
          alignItems="center"
          justifyContent="center"
        >
         <ProductCompanyInformation productId={productId} companyId={companyId}/> 
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Store</TableCell>
                  <TableCell align="left">Address</TableCell>
                  <TableCell align="left">City</TableCell>
                  <TableCell align="left">Province</TableCell>
                  <TableCell align="left">Most recent date</TableCell>
                  <TableCell align="left">Most recent price</TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataSourceFilter.map((row, i) => (
                  // <span>{row.image_url}</span>
                  <ProductStoreMostRecentPriceItem key={i} productStore={row} productId={productId} companyId={companyId}/>
                ))}
                {/* {loadMore && (
                  <ItemLoading doLoadMore={doLoadMore} colSpanValue={4} />
                )} */}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
    </>
  );
}
