import React, { useState,useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import VizSensor from "react-visibility-sensor";
import axios from "axios";
import envConfig from "../../bin/env.config.js";
import { useSelector } from "react-redux";
import Divider from "@material-ui/core/Divider";
import CircularProgress from '@material-ui/core/CircularProgress';
import ProductExternalLink from "../Product/ProductExternalLink";
import ProductImage from "../Common/ProductImage";
const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: "white",
    width: "100%",
    paddingLeft: "24px",
    paddingRight: "24px",
  },
  container: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  productImage: {
    width: "150px",
    height: "150px",
    objectFit: "contain", //
    // margin: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column  ",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  productName: {
    textTransform: "capitalize",
    // fontWeight: "bold",
    fontFamily: "Roboto",
  },
  productSize: {
    textTransform: "capitalize",
    fontWeight: "bold",
    fontFamily: "Roboto",
  }, 
}));
export default function ProductCompanyInformation({productId,companyId}) {
  const classes = useStyles();
  const token = useSelector((state) => state.session.token);
  const [productCompany, setProductCompany] = useState({company_product_id:'-', fullname:'-', size:'-'  });
  const [isVisible, setIsVisible] = useState(false);
  const doLoadData = (isVisibleChanged) => {
    isVisibleChanged&&setIsVisible(isVisibleChanged)    
  };
   
  useEffect(() => {
    const fetchData = async () => {
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/product-store/product-company`;
      const result = await axios.post(url,
        {
          productId:productId,
          companyId:companyId,
        },
        {
          headers: {
            Authorization: token,
          },
        });
      setProductCompany(result.data.data);
    };    
    productId&&companyId&&isVisible&& fetchData();    
  }, [companyId, productId, isVisible]);
  return ( 
    <VizSensor onChange={doLoadData}>
      <div className={classes.root}>
        {productCompany.company_product_id==='-'?(
                                  <CircularProgress size={16} />
                                ):(
                                  <div className={classes.container}>
                                    {/* <div className={classes.productImage}> */}
                                      < ProductImage  product_id={productId} width={'150px'} height={'150px'} hide_multiple_icon={true}/>
                                    {/* </div> */}
                                    <div className={classes.titleContainer}>
                                      <div className={classes.productName}>
                                      {productCompany.fullname} {productCompany.size} 
                                      </div>  
                                      <div className={classes.productSize}>
                                        {productCompany.size} #{productId}
                                        <ProductExternalLink productId={productId} />    
                                      </div>  
                                    </div>  
                                  </div>
                                )}          
        <Divider style={{marginLeft:"-24px",marginRight:"-24px"}} />
      </div>
                 
    </VizSensor>
    // )}
    // </>
            
  );
}
