import React, { useState,useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  
}));

export default function CitiesDropDownBox({cities, onChange}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCity, setSelectedCity] = useState({'city':'', 'province':''});
 const handleOpenCityMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseCityMenu = (city) => {
    setAnchorEl(null);
    // console.log(city);
    if (typeof city === 'object' && city.city) {
      setSelectedCity(city);
      onChange(city);
    }
  };
  return (
    <>
    <Button color="primary" className={classes.buttonCategoryMenu} onClick={handleOpenCityMenu}
           endIcon={<ExpandMoreIcon/>}
          >{selectedCity.city!=''?selectedCity.city:'All cities'}</Button>
    <Menu
        id="city-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseCityMenu}
      >
        <MenuItem onClick={()=>handleCloseCityMenu({city:'All cities',province:''})}>All cities</MenuItem>
        {cities&&cities.map((city,index)=>
              <MenuItem key={index} selected={city.city===selectedCity.city} onClick={()=>handleCloseCityMenu(city)}>{city.province} - {city.city}</MenuItem>
        )}
    </Menu>
    </>    
  );
}
